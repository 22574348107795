body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html {
    height: 100%;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    height: 10%;
}

ol {
    list-style-type: none;
}

li {
    margin-bottom: 3%;
}


/* Background image phone*/
.bgSizeCover {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    background-image: url(images/home/bg-image.jpg);
    background-size: cover;
    resize: both;
    background-position: center;
}

/* ------------------------*/


/*Video home page*/
.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.video-container video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*--------------------------------------*/


.ml-auto {
    margin-right: 5%;
}

.nav-link-custom {
    margin-left: 8%;
    font-size: 0.8em;
    font-family: "Arial";
}


/*nuria revuelta logo*/
.nav-brand {
    width: 30%;
}

@media (max-width: 450px) {
    .nav-brand {
        width: 70%;
    }
}

@media (max-width: 1200px) {
    .nav-brand {
        width: 35%;
    }
}

/*------------------------------*/

/*nuria revuelta logo*/
.logo {
    width: 60%;
    margin-left: 10%;
}

@media (max-width: 450px) {
    .logo {
        width: 200%;
        margin-left: 5%;
    }
}

@media (min-width: 1681px) {
    .logo {
        width: 40%;
        margin-left: 10%;
    }
}

/*--------------------------------------*/


/*full horizontal scroll start*/
.box {
    background-color: transparent;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    display: flex;
    padding-top: 2%;
    padding-left: 3%;
    padding-bottom: 3%;
    align-items: center;
}

.scroll-images {
    margin-right: 2%;
    height: 100%;
    object-fit: contain;
    padding-bottom: 1%;
}

@media (max-width: 450px) {
    .box {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        align-items: center;
        text-align: center;
        padding-left: 0%;
        padding-right: 2%;
    }

    .box::-webkit-scrollbar {
        display: none;
    }
}


@media (max-width: 450px) {
    .scroll-images {
        margin-right: 0%;
        margin-left: 3.5%;
        alignment: center;
        width: 90%;
        flex-direction: column;
        height: auto;
        padding-bottom: 10%;

    }
}

/*---------------------------------------*/


/*photography*/
.images-in-photography-scroll {
    height: 100%;
    object-fit: contain;
}

.scroll-images-photography {
    height: 100%;
    object-fit: contain;
    width: 95%;
    background-color: transparent;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
}

.photo-links {
    font-style: italic;
    font-size: 0.8rem;
    width: 90%;
    color: black;
}

/*--------------------------------------*/


.img-responsive {
    background-color: blue;
    background: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    margin-right: 5px;
}


.responsive-div {
    margin-bottom: 3%;
    display: flex;
}


/*HOVER Photgraphy*/

.content {
    opacity: 0;
    font-size: 0.8rem;
    font-family: Arial;
    font-style: italic;

    position: absolute;

    top: 0%;
    left: 0%;

    width: 100%;
    height: 100%;

    border: 2px solid white;

    color: white;
    background-color: white;
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
}

.col-sm-3 .content:hover {
    opacity: 0.6;
}

.col-sm-3 .content .text {
    padding-top: 50%;
    height: 0;
    opacity: 1;

}

.col-sm-3 .content:hover .text {
    opacity: 1;
}

/*--------------------------------------*/


/*HOVER FASHION PROJECTS*/
.hover-luis:hover {
    border: 1px solid black;
}

/*--------------------------------------*/



